<template>
  <div>
    <el-container>
      <el-header height="100%">
          <vheader class="hidden-sm-and-down"></vheader>
          <ivheadera class="hidden-md-and-up"></ivheadera>
          <div class="top-img">
             <img class="hidden-sm-and-down" width="100%" src="../../assets/images/en/A/topimg.jpg" alt="">
             <img class="hidden-md-and-up" width="100%" src="../../assets/images/en/A/A1-topImg-m.png" alt="">
             <div class="top-img-top">
               <p>INDUSTRIES</p>
               <span>产业介绍</span>
             </div>
           </div>
      </el-header>
      <el-main>
        <el-row type="flex" justify="center" class="isab">
          <el-col :xs="24" :sm="24" :md="24" :lg="24">
            <div class="w isab-mib clearfloat food-list-hook">
              <div class="ec-top-foot">
              <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item > <router-link to="/">首页</router-link></el-breadcrumb-item>
                <el-breadcrumb-item>产业介绍</el-breadcrumb-item>
              </el-breadcrumb>
            </div>
            <a href="###" name="pill"></a>
              <div class="bgb-bss">
                <img src="../../assets/images/A/A2-bgb.jpg" class="bgb-i hidden-sm-and-down" alt>
                <img src="../../assets/images/A/bgb-ia.png" class="bgi-is hidden-md-and-up" alt>
              </div>
              <div class="mi">
                <el-row type="flex" justify="center" class="cards">
                  <el-col :xs="24" :sm="24" :md="12" class="card" v-for="(item, index) in cityb" :key="index">
                    <div class="card-af">
                      <el-col :xs="24" :sm="24" :md="24" >
                        <div class="card-icon">
                          <img :src="item.IMAGE" alt="">
                          <div class="icon-cn-txt" v-html="item.TITLE"></div>
                          <div class="icon-en-txt" v-html="item.ONE"></div>
                        </div>
                      </el-col>
                      <el-col :xs="24" :sm="24" :md="24">
                        <div class="card-txt">
                           {{item.SHORTCONTENT}}
                        </div>
                      </el-col>
                    </div>
                  </el-col>
                </el-row>
                <div class="idvo" v-for="item in places" :key="item">
                  <img width="100%" :src="item" alt>
                </div>
              </div>
              <a name="stra"></a>
              <div class="bgb-bsz">
                <img src="../../assets/images/A/A2-bgh.png" class="bgb-i hidden-sm-and-down" alt>
                <img src="../../assets/images/A/bgb-ib.png" class="bgi-i hidden-md-and-up" alt>
              </div>
              <div class="mis">
                <div class="main-x" v-for="(item, index) in cityc" :key="index">
                  <div class="main-i">
                    <div class="main-i-i">
                      <div class="main-ttt">0{{index+1}}</div>
                      <div class="main-l">
                        <img class="mian-ss" :src="item.IMAGE" alt>
                        <h3>{{item.TITLE}}</h3>
                        <p v-html="item.ONE"></p>
                      </div>
                    </div>
                    <div class="main-r"> <div class="sew"> {{item.TWO}} </div></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="ss">
              <div class="w">
                <a name="futu"></a>
                <div class="bgb-bs">
                  <img src="../../assets/images/A/A2-bgi.png" class="bgb-i hidden-sm-and-down" alt>
                  <img src="../../assets/images/A/bgb-ii.jpg" class="bgi-i hidden-md-and-up" alt>
                </div>
                <div class="foot miss">
                    <div class="foot-i" v-for="(item, index) in cityd" :key="index">
                      <div class="foot-i-a">
                        <div class="foot-x">0{{index+1}}</div>
                        <div class="foot-t">
                          <div class="foot-h" v-html="item.ONE"></div>
                          <div class="foot-m">{{ item.TITLE }}</div>
                        </div>
                      </div>
                      <div class="foot-txt"><div class="foot-txt-t"> {{ item.SHORTCONTENT }}</div></div>
                    </div>
                </div>
              </div>
              <div class="sin">
                <el-popover
                  placement="right"
                  title="支柱产业"
                  width="150"
                  height='20'
                  trigger="hover">
                  <a href="#pill" slot="reference"></a>
                </el-popover>
                <el-popover
                  placement="left"
                  title="战略新兴产业"
                  width="150"
                  trigger="hover">
                  <a slot="reference" href="#stra"></a>
                </el-popover>
                <el-popover
                  placement="left"
                  title="未来产业"
                  width="150"
                  trigger="hover">
                  <a slot="reference" href="#futu"></a>
                </el-popover>
              </div>
              <gotop></gotop>
            </div>
          </el-col>
        </el-row>
      </el-main>
      <el-footer>
        <vfooter class="hidden-sm-and-down"></vfooter>
        <ivfooter class="hidden-md-and-up"></ivfooter>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
import Gotop from '../../components/gotop'
import { szcityba, szcitybb, szcitybc, szcitybd } from '../../api'
export default {
  data () {
    return {
      citya: {},
      cityb: {},
      cityc: {},
      cityd: {},
      listHeight: [],
      places: {}
    }
  },
  created () {
    this._calculateHeight()
  },
  mounted () {
    szcityba().then(res => {
      // console.log(res.data)
      this.citya = res.data
      this.places = res.data[0].ONE
      let Arr = this.places.split('</p>')
      // eslint-disable-next-line no-array-constructor
      let Src = new Array()
      for (var y = 0; y < Arr.length; y++) {
        if (Arr[y].indexOf('src') !== -1) {
          Src.push(Arr[y].match(/src="(\S*)"/)[1])
        }
      }
      this.places = Src
      // console.log(this.places)
      // console.log(this.citya)
    })
    szcitybb().then(res => {
      // console.log(res.data)
      // console.log(1)
      this.cityb = res.data
    })
    szcitybc().then(res => {
      // console.log(res.data)
      this.cityc = res.data
      // console.log(this.cityc)
    })
    szcitybd().then(res => {
      // console.log(res.data)
      this.cityd = res.data
      // console.log(this.cityd)
    })
  },
  methods: {
    _calculateHeight () {
      // let foodList = this.$ref.foodsWarper
    }
  },
  components: {
    Gotop
  }
}
</script>

<style src='../../assets/css/CityA2.css' ></style>

<style>
.el-main {
    margin-top: 0;
    overflow: initial;
}
.el-popover {
  padding: 0;
}
.el-popover .el-popover__title {
  height: 20px;
  font-size: 0.3rem;
  text-align: center;
  align-items: center;
  line-height: 34px;
  color: #6a6969;
}
</style>
